.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #000;
  opacity: 0.75;
  animation: fade-in 0.3s;
}

.container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  align-items: center;
  justify-content: center;

  .modal {
    margin: 20px;
    border-radius: 6px;
    padding: 20px;
    background-color: #fff;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}
