@import '../../styles/base';

.form {
  > h2 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    color: #000;
  }

  fieldset {
    margin-bottom: 16px;

    > * {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .error-wrapper {
    margin-top: -24px;
    margin-bottom: 8px;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  .logos {
    display: flex;
    align-items: center;

    .x-mark {
      width: 15.23px;
      height: 15.24px;
      margin: 0 24px;
    }

    .logo {
      margin: 23.26px 0;
    }
  }

  .logo {
    margin: 18px 0;
  }

  .highlight {
    @extend %wrapper;
    background: var(--ghost);
    padding: 8px 24px;
    border-radius: 4px;
    text-align: center;
    font: 600 15px/1 var(--font-family);
  }
}

.check-label {
  @extend %checkbox;
  margin: 40px 0 24px;
}

.password {
  opacity: 0;
  height: 0;
  width: 0;
}

.forgot-password {
  font: 400 15px/22px var(--font-family);
  text-decoration: none;
}

.or {
  display: block;
  position: relative;
  margin: #{9.5px + 24px} auto;
  width: 100%;
  height: 1px;
  background: #dfe1e5;

  &::after {
    content: attr(data-text);
    display: block;
    padding: 0 10px;
    font: 300 13px/20px var(--font-family);
    background: #fff;
    color: #6b748d;
    white-space: nowrap;
    @extend %center;
  }
}

.login-btns {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
