:root {
  --white: #fff;
  --black: #000;

  // Brand Colors
  --turquoise: #00d5e0;
  --light-sea-green: #2db2b2;
  --very-light-blue: #7280ff;
  --wild-strawberry: #ff3f8f;
  --amber: #ffc000;

  // Grayscale
  --blackberry: #231c45;
  --text-dark: #2c2e57;
  --text-medium: #535678;
  --text-light: #6b748d;
  --text-placeholder: #c7c8cc;
  --lines: #dfe1e5;
  --ghost: #f4f4f7;
  --background-gray: #f8f9fc;

  // Primary
  --primary: #4456dd;
  --primary-hover: #3445c1;
  --primary-selected: #e7f0ff;

  // Notifications
  --success: #27885f;
  --success-light: #ddf6e2;
  --error: #d4388a;
  --error-light: #f9ecf2;
  --warning: #93740a;
  --warning-light: #fff09f;
  --info: #5468fb;
  --info-light: #e7f0ff;

  // Dark Theme
  --blackberry-light: #3a3262;
  --blueberry: #494172;
  --blueberry-lines: #605889;
  --blackberry-text: #9590a5;

  // Others
  --happy-green: #86ca50;
  --water-pink: #e99bc5;
  --grapefruit: #fd7a64;
  --background-blue: #f1f3ff;
  --background-app: #f4f7fc;
  --background-app-darker: #e3e7f2;
  --block-background-app: #e3e7f2;
  --highlight: #5765e5;
  --highlight-disable: #4a4ea2;
  --dark-wild-strawberry: #b92774;
  --wild-strawberry-disable: #4f2354;

  --font-family: 'Filson Pro', -apple-system, BlinkMacSystemFont, Hiragino Sans,
    Hiragino Kaku Gothic ProN, BIZ UDPGothic, Noto Sans JP, Meiryo UI,
    sans-serif;
  --font-family-mono: 'SF Mono', monospace;

  --base-font-size: 16px;
  --min-device-width: 320px;
  --header-height: 70px;
}
