@import '../../styles/base';

.figure {
  margin: 0 auto;
}

.heading {
  margin: 16px 0 12px;
  font: 700 20px/28px var(--font-family);
  text-align: center;
  color: var(--text-dark);
}

.desc {
  max-width: 480px;
  text-align: center;
  font: 400 17px/28px var(--font-family);
  color: var(--text-light);
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;

  button {
    margin-right: 8px;
    padding: 8px;
  }

  :last-child {
    margin-right: 0;
  }
}

.remember-preference {
  @extend %checkbox;
  margin-top: 16px;
}
