@import '../../styles/base';

.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font: 600 20px/32px var(--font-family);
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
    }

    .reason {
      font: 400 17px/28px var(--font-family);
      margin-bottom: 16px;
      text-align: center;
    }

    .note {
      font: 600 15px/22px var(--font-family);
      margin-bottom: 16px;
      text-align: center;
    }

    .bottom {
      width: 100%;
      margin-top: auto;
      display: flex;
      flex-direction: row;

      .button {
        padding: 8px;
      }

      .left-button {
        margin-right: 8px;
      }
    }

    .app-store-message {
      color: var(--primary);
      font: 600 11px/1 var(--font-family);
      margin-top: 12px;
    }
  }
}
