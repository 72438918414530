@import '../../styles/base';

.method-select {
  position: relative;
  margin-bottom: 32px;

  .selected-method {
    border: 1px solid var(--lines);
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    position: relative;

    .saved-method {
      border: 0;
    }

    .arrow {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 12px;
      right: 16px;
      will-change: transform;
      transition: transform 0.15s ease;
      background: url('../../assets/icon-arrow-right.svg') center no-repeat;
      background-size: contain;
      transform: rotate(90deg);
    }
  }

  &.is-collapsed {
    .selected-method {
      border-radius: 6px;
      border-bottom: 1px solid var(--lines);

      .arrow {
        transform: rotate(0deg);
      }
    }
  }

  .last-item {
    border-bottom: 1px solid var(--lines);
    border-radius: 0 0 6px 6px;
  }
}

.method-candidates {
  max-height: 48px * 3;
  overflow: auto;
}

.method-options {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 49px;
  border: 1px solid var(--lines);
  border-radius: 0 0 6px 6px;
  background: #fff;
  overflow: auto;

  .saved-method {
    &:last-child {
      border-bottom: 0;
    }

    &.option-add-card {
      h3 {
        color: var(--primary);
      }
    }
  }
}

.saved-method {
  user-select: none;
  cursor: pointer;
  display: flex;
  border: 1px solid var(--lines);
  border-width: 0 0 1px;
  align-items: center;
  padding: 0 8px 0 16px;

  &:hover {
    background: var(--background-gray);
  }

  h3 {
    display: flex;
    align-items: center;
    height: 48px;
    letter-spacing: 0.02em;
    color: var(--text-dark);
    font: 300 15px/48px var(--font-family);

    .masked {
      margin-left: 8px;
      font-weight: 600;
    }

    .test-label {
      padding: 0 8px;
      border-radius: 4px;
      font: 600 8px/20px var(--font-family);
      text-transform: uppercase;
      margin-left: 8px;

      &:global([class*='success']) {
        color: var(--success);
        border: 1px solid var(--success-light);
      }

      &:global([class*='soft-decline']) {
        color: var(--error);
        border: 1px solid var(--error-light);
      }

      &:global([class*='hard-decline']) {
        color: var(--error);
        background: var(--error-light);
      }
    }
  }
}

.note {
  margin-top: 8px;
  font-size: 13px;
  line-height: 22px;
  color: var(--text-light);
}
