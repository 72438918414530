@import '../../styles/base';

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .lottie-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
  }

  h1 {
    font: 600 18px/18px var(--font-family);
    color: var(--blackberry);
  }

  .information-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 8px;
    width: 100%;
    height: 64px;
    text-align: center;
    font: 500 16px/16px var(--font-family);
    color: var(--info);
    background: var(--info-light);
  }
}
