@import '../../styles/base';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 40px 24px 24px;
  width: 432px;
  height: 432px;
  text-align: center;
  background-color: var(--background-gray);

  .logo-container {
    .logo {
      margin: 0 auto;
      width: 310px;
    }
  }

  .waiting {
    animation: waiting 300s linear forwards;
    transform-origin: 50% 50%;
    transform: rotate(270deg) scaleY(-1);

    &.timeout {
      display: none;
      stroke-dashoffset: 1;
    }
  }

  .waiting-timer {
    font-size: 20px;
    font-family: var(--font-family-mono);
  }

  .info-box {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.timeout {
      margin-top: -64px;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;

    h2 {
      font: 600 13px/16px var(--font-family);
      text-align: left;

      &.center {
        font: 600 16px/28px var(--font-family);
        text-align: center;
      }
    }

    p {
      font: 300 13px/16px var(--font-family);
      text-align: left;
    }

    [type='button'] {
      margin-top: 8px;
    }
  }
}

.note {
  margin-top: 8px;
  width: 100%;
  font: 300 13px/22px var(--font-family);
  color: var(--text-light);
}

@keyframes waiting {
  to {
    stroke-dashoffset: 1;
  }
}

@include respond-to(mobile) {
  .container {
    width: auto;
  }
}
