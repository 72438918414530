@import '../styles/base';

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  @extend %center-wrapper;
  flex: 1;

  img {
    display: block;
    animation: rotating 0.5s linear infinite;
  }
}
