@import '../../styles/base';

.wrapper {
  flex: 1 1 calc(100vh - var(--header-height));
  height: calc(100vh - var(--header-height));
  background: var(--background-gray);
  padding: 22px 24px 24px 24px;
  overflow: auto;

  .title {
    margin: 24px 0 16px 0;
    font: 600 15px/26px var(--font-family);
  }

  .body {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
  }

  .accordion {
    &:nth-child(n + 1) {
      margin-top: 8px;
    }
  }
}

@include respond-to(desktop) {
  .wrapper {
    .body {
      max-width: 700px;
    }
  }
}
