@import '../../styles/base';

.trigger {
  &.has-margin-left {
    margin-left: 8px;
  }

  .trigger-item {
    position: relative;

    // info icon
    > img {
      margin-left: 0;
    }

    .arrow {
      display: none;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      z-index: 1;
    }

    .arrow-border {
      display: none;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid rgba(202, 204, 216, 0.18);
      top: 19px;
      left: -5px;
    }
  }

  .popup {
    display: none;
    position: absolute;
    z-index: 1;
    transform: translate(calc(-100% + 40px), 30px);
    box-shadow: 0 4px 16px rgba(68, 86, 221, 0.18);
    background: none;
    border-radius: 6px;
    width: 376px;
    width: fit-content;

    @media only screen and (max-width: 320px) {
      width: 300px;
      left: 50%;
      transform: translate(-50%, 30px);
    }

    @media only screen and (min-width: 321px) and (max-width: 390px) {
      width: 328px;
      left: 50%;
      transform: translate(-50%, 30px);
    }

    .inner {
      color: var(--text-light);
      background: #fff;
      border-radius: 6px;
      padding: 24px;
      font: 300 13px/22px var(--font-family);

      h6 {
        font: 600 13px/16px var(--font-family);
        color: var(--text-dark);
      }

      ul {
        margin-top: 8px;
      }

      li {
        font: 300 13px/22px var(--font-family);
        color: var(--text-light);
        list-style-type: disc;
        margin-left: 22px;
      }

      em {
        background: var(--info-light);
        border-radius: 4px;
        color: var(--info);
        display: inline-block;
        margin: 0 2px;
        padding: 0 6px;
        font: 300 13px/19px var(--font-family-mono);
      }
    }
  }

  &.bottom-right {
    .popup {
      transform: translate(-16px, 30px);

      @media only screen and (max-width: 320px) {
        transform: translate(-50%, calc(-100% - 16px));
      }

      @media only screen and (min-width: 321px) and (max-width: 390px) {
        transform: translate(-50%, calc(-100% - 16px));
      }
    }
  }

  &.top-left {
    .trigger-item {
      .arrow,
      .arrow-border {
        top: -16px;
        transform: rotate(180deg);
      }
    }

    .popup {
      transform: translate(calc(-100% + 40px), calc(-100% - 16px));

      @media only screen and (max-width: 320px) {
        transform: translate(-50%, calc(-100% - 16px));
      }

      @media only screen and (min-width: 321px) and (max-width: 390px) {
        transform: translate(-50%, calc(-100% - 16px));
      }
    }
  }

  &.top-right {
    .trigger-item {
      .arrow,
      .arrow-border {
        top: -16px;
        transform: rotate(180deg);
      }
    }

    .popup {
      transform: translate(-16px, calc(-100% - 16px));

      @media only screen and (max-width: 320px) {
        transform: translate(-50%, calc(-100% - 16px));
      }

      @media only screen and (min-width: 321px) and (max-width: 390px) {
        transform: translate(-50%, calc(-100% - 16px));
      }
    }
  }
}

.trigger {
  &:hover,
  &.display {
    .popup,
    .arrow,
    .arrow-border {
      display: initial;
    }
  }
}
