@import '../../styles/base';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .connection {
    position: relative;
    display: flex;
    width: 100%;
    padding: 36px 51.5px;
    justify-content: center;
    align-items: center;

    .background-light {
      position: absolute;
      width: 128px;
      height: 128px;
      left: calc(50% - 128px / 2);
      top: calc(50% - 128px / 2);
      background: rgba(154, 160, 218, 0.14);
      border-radius: 128px;
    }

    .logo-group {
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 128px;

      .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background: white;
        box-shadow: 0px 2px 8px rgba(68, 86, 221, 0.12);
        border-radius: 18px;

        .logo {
          width: 47.28px;
        }
      }

      .connect-dots {
        display: flex;
        flex-direction: row;
        height: 8px;
        width: 40px;
        margin: 0 12px;
        justify-content: space-between;

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: var(--background-app-darker);
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;

    .title {
      color: var(--text-dark);
      font: 600 20px/32px var(--font-family);
    }

    .content {
      color: var(--text-light);
      font: 300 17px/32px var(--font-family);
      margin-bottom: 16px;
    }

    .button {
      @extend %btn;
      background: var(--primary) url('../../assets/icon-lock-white.svg') 16px
        center no-repeat;
      background-size: 16px;
      padding: 0 16px;

      &:enabled {
        &:hover {
          background: var(--primary-hover)
            url('../../assets/icon-lock-white.svg') 16px center no-repeat;
          background-size: 16px;
        }
      }
    }
  }

  .error-wrapper {
    margin-top: -8px;
    margin-bottom: 8px;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
