@import '../../styles/base';

.information-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 8px;
  width: 100%;
  height: 64px;
  text-align: center;
  font: 500 16px/16px var(--font-family);
  color: var(--text-light);
  background: var(--background-gray);

  b {
    color: var(--text-dark);
  }
}
