@import '../../styles/base';

.secure {
  display: block;
  text-align: center;
  color: var(--info);
  margin-top: 16px;
  font: 600 11px/11px var(--font-family);
}

.action {
  .or {
    display: block;
    position: relative;
    margin: #{9.5px + 24px} auto;
    width: 100%;
    height: 1px;
    background: var(--lines);

    &::after {
      content: attr(data-text);
      display: block;
      padding: 0 10px;
      font: 300 13px/20px var(--font-family);
      background: #fff;
      color: var(--text-light);
      white-space: nowrap;
      @extend %center;
    }
  }
}

.disabled {
  color: var(--text-light);
}

.enabled {
  color: var(--primary);
}

.third-party {
  > * {
    margin-top: 8px;
  }

  button {
    width: 100% !important;
    border-radius: 6px;
    height: 48px;
    display: block;
  }

  > .btn-google-pay-container {
    width: 100%;

    button {
      padding-top: 4px;
    }
  }

  > .btn-apple-pay {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
  }

  .fake-apple-pay {
    height: 48px;
    width: 100%;
    background: black;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 6px;
    cursor: pointer;
  }
}

.promotion-form {
  margin-bottom: 40px;

  .form {
    display: flex;
    position: relative;
    flex-direction: row;

    .input {
      flex: 1;
    }

    .btn-apply {
      @extend %vertical-center;
      width: 78px;
      height: 33px;
      right: 7.5px;
      color: var(--primary);
      font: 600 14px/21px var(--font-family);
      padding: 0 10px;
      background: var(--primary);
      color: #fff;
      border-radius: 4px;
      letter-spacing: 0.02em;

      &:focus {
        outline: 0;
      }
    }
  }

  .promotion-code-notice {
    margin-top: 4px;
    font: 600 11px/16px var(--font-family);
    color: var(--primary);
  }
}

.apple-pay-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    border-radius: 8px;
    background: #fff;
    padding: 48px;
    width: 100%;
    height: 100%;

    h2 {
      font: 600 20px/32px var(--font-family);
      color: var(--text-dark);
      margin: 64px 0 16px;
    }

    p {
      margin-bottom: 32px;
      font-size: 15px;
      line-height: 28px;
      color: var(--text-dark);
    }

    nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > a,
      > button {
        flex: 1;
        height: 48px;
        line-height: 48px;
        border-radius: 6px;
        text-align: center;
        font-weight: 600;
        text-decoration: none;
      }

      > a {
        color: var(--primary);
        background: #fff;
        border: 1px solid var(--primary);
        margin: 0 0 16px 0;
      }

      > button {
        background: var(--primary);
        color: #fff;
      }
    }
  }
}

@include respond-to(desktop) {
  .apple-pay-overlay {
    .popup {
      width: 80%;
      height: 80%;
      max-width: 702px;
      max-height: 408px;

      nav {
        flex-direction: row;

        > a {
          margin: 0 16px 0 0;
        }
      }
    }
  }
}
