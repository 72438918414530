@import '../../styles/base';

.main {
  background: var(--background-gray);
}

.merchant-header {
  color: var(--white);
  padding: 0 0 30px;
  display: flex;
  @extend %wrapper;
  width: auto;

  .merchant {
    flex: 1;

    > h2 {
      font: 600 30px/43px var(--font-family);
    }

    .order-confirmation {
      color: var(--text-dark);
      display: block;
      font: 600 16px/32px var(--font-family);

      .number {
        font: 300 15px/15px var(--font-family);
        color: var(--text-light);
        white-space: nowrap;
      }
    }
  }

  .control {
    display: flex;
    align-items: center;

    .button {
      display: flex;
      white-space: nowrap;
      cursor: pointer;

      &.collapse {
        > img {
          transform: rotate(90deg);
        }
      }

      > img {
        transition: transform 0.2s ease;
      }

      .header-total {
        letter-spacing: 0.1em;
        color: var(--highlight);
        margin-right: 4px;
        font: 600 15px/24px var(--font-family);
      }
    }
  }

  @media only screen and (max-width: 480px) {
    margin: 0 24px;
  }
}

.order-details {
  @extend %wrapper;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height 0.15s ease, opacity 0.15s ease;
  will-change: max-height, opacity;
  margin: -6px auto 0;
  padding-bottom: 28px;
  background-color: var(--background-gray);

  &.collapsed {
    max-height: 0;
    opacity: 0;
    margin-top: -26px;
    visibility: hidden;
    padding-bottom: 8px;
  }

  li {
    display: flex;
    padding: 4px 0;

    &:not(.total):not(.subtotal) {
      margin-bottom: 16px;
    }

    &.subtotal {
      border-top: 1px solid var(--lines);
      padding-top: 16px;
      flex-direction: column;

      .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px 0;

        .info {
          .title {
            font: 600 13px/13px var(--font-family);
            padding-left: 0;
          }

          .subtitle {
            margin-top: 6px;
            font: 300 13px/13px var(--font-family);
            color: var(--text-light);
          }
        }

        .amount {
          font: 600 13px/13px var(--font-family);
          color: var(--text-light);
        }

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }

    &.total {
      padding: 0;
      margin-top: 24px;
      font-weight: 600;

      .info {
        .title {
          padding-left: 0;
          color: var(--text-dark);
          font: 600 18px/1 var(--font-family);

          > span {
            color: var(--text-light);
          }
        }
      }

      .amount {
        color: var(--highlight);
      }
    }

    img {
      border: 1px solid var(--lines);
      border-radius: 6px;
      margin-right: 8px;
      object-fit: cover;
    }

    .info {
      flex: 1;
      width: calc(100% - 64px - var(--header-height));
      padding: 0 21px 0 0;
      color: var(--text-dark);
      display: flex;
      justify-content: center;
      flex-direction: column;

      .title {
        font: 600 13px/16px var(--font-family);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text-dark);
      }

      .desc {
        height: 22px;
        font: 300 13px/22px var(--font-family);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text-light);
      }
    }

    .amount {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      color: var(--text-dark);
      width: 70px;

      .price {
        font: 600 16px/28px var(--font-family);
        color: var(--text-dark);
      }

      .quantity {
        font: 300 13px/22px var(--font-family);
        color: var(--text-light);
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 0 24px 28px;
  }
}

@include respond-to(desktop) {
  .main {
    padding: 48px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - var(--header-height));
  }

  .merchant-header {
    max-width: none;
    margin: 0;
    padding: 0 80px 16px;

    .control {
      display: none;
    }
  }

  .order-details {
    flex: 1;
    margin: 0;
    width: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;

    &.collapsed {
      max-height: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      opacity: 1;
      visibility: visible;
    }

    .items {
      padding: 0 80px;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }

    ul.subtotal {
      padding: 0 80px;
    }

    li {
      padding: 0;

      &.total {
        .amount {
          color: var(--highlight);
        }
      }

      img {
        width: 80px;
        height: 80px;
        border: 1px solid var(--lines);
        border-radius: 6px;
      }

      .info {
        width: calc(100% - 80px - 70px);

        .title {
          font-size: 17px;
          line-height: 30px;
        }
      }
    }
  }
}
