@import '../../styles/base';

.form {
  position: relative;

  fieldset {
    width: 100%;
    display: flex;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    > * {
      width: 100%;
    }

    &.col-2 {
      > * {
        width: calc((100% - 16px) / 2);

        &:first-child {
          margin-right: 16px;
        }
      }
    }

    &.gender-n-dob {
      .gender {
        flex: 1;
        margin-right: 16px;
      }

      .dob {
        display: flex;
        flex: 1;
      }
    }

    &.building {
      margin-bottom: 16px;
    }
  }

  .check-label {
    @extend %checkbox;
    margin-bottom: 40px;
  }
}

.btn {
  margin-top: 8px;
}

.zip-n-state {
  .zip {
    flex: 1;
    margin-right: 16px;
  }

  .state {
    flex: 1;
  }
}
