@import '../../styles/base';

.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font: 600 20px/32px var(--font-family);
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
    }

    p {
      font: 400 17px/28px var(--font-family);
      margin-bottom: 16px;
      text-align: center;
    }

    .bottom {
      width: 100%;
      margin-top: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .cancel {
        background-color: transparent;
        color: var(--primary);
      }
    }
  }
}

.modal {
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }

  .message {
    font-size: 17px;
    line-height: 28px;
    margin: 16px 0 24px 0;
    text-align: center;
  }

  .action {
    margin: 0 auto;
    width: 300px;
  }
}
