@import '../../styles/base';

.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    font: 600 20px/32px var(--font-family);
    color: var(--text-dark);
    margin-bottom: 16px;
  }

  p {
    font: 300 15px/22px var(--font-family);
    margin-bottom: 8px;
  }
}
