.layout {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .lottie-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-self: center;
  }
}
