@import './styles/reset';
@import './styles/base';
@import './styles/font';
@import './styles/vars';

* {
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: var(--background-gray);
}

#root {
  height: 100%;
  margin: 0 auto;
  background: var(--background-gray);
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--base-font-size);
  color: var(--text-dark);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  object-fit: contain;
  display: block;
}

button {
  cursor: pointer;
}

a {
  color: var(--primary);
}

input {
  font-family: var(--font-family);
  appearance: none;
  padding: 0;
}

.rwd-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn {
  @extend %btn;
}

.main-form-title {
  font: 600 20px/1 var(--font-family);
  margin-bottom: 40px;
  color: var(--text-dark);
  letter-spacing: 0.02em;
}

.main-greetings {
  font: 600 20px/32px var(--font-family);
  margin-bottom: 16px;
}

.main-desc {
  margin: 0 0 16px;
  font: 300 15px/22px var(--font-family);
  color: var(--text-dark);

  b {
    font: 600 15px/22px var(--font-family);
  }
}

.error {
  color: var(--error);
  font-size: 14px;
  line-height: 16px;
  text-indent: 8px;
}

.select-box {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    white-space: nowrap;
    font-size: 13px;
    line-height: 13px;
    color: var(--text-light);
    margin-bottom: 8px;

    &.label-error {
      color: var(--error);
    }
  }

  select {
    border: 1px solid var(--lines);
    border-radius: 6px;
    height: 48px;
    color: var(--text-dark);
    width: 100%;
    padding: 0 16px;
    appearance: none;
    font: 14px/48px var(--font-family);
    background: url('./assets/icon-chevron-down.svg') 91% no-repeat;
    background-size: 24px;

    &.select-error {
      border: 1px solid var(--error);
      background: url('./assets/icon-chevron-down-pink.svg') 91% no-repeat;
      background-size: 24px;
    }
  }
}

.price-amount {
  white-space: nowrap;
}

::placeholder {
  color: var(--text-placeholder);
  font-weight: 300;
}

// Fix the Adyen's loading spinner's position
.adyen-checkout__3ds2-device-fingerprint {
  height: 100%;
}

@include respond-to(desktop) {
  html {
    overflow-y: hidden;
    overflow-x: auto;
  }

  .rwd-wrapper {
    &:not(.token-flow) {
      &:not(.no-rwd) {
        flex-direction: row;
      }

      > aside {
        display: flex;
        flex-direction: column;
        height: 100vh;
        flex: 0 1 30%;
        min-width: 543px;
      }
    }
  }
}

.apple-auth-btn {
  border-radius: 6px !important;
  font: 600 16px / 16px var(--font-family);
}
