@import '../styles/base';

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
  min-width: var(--min-device-width);
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 24px rgba(51, 23, 88, 0.12);

  .btn-back {
    cursor: pointer;
    position: absolute;
    left: 24px;
    top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .text {
      margin-left: 8px;
      font: 300 16px/24px var(--font-family);
      color: var(--primary);
    }
  }

  .ribbon {
    &.test {
      position: absolute;
      right: -40px;
      top: 0;
      transform: rotate(45deg) translateY(94%);

      &::after {
        content: 'テスト中';
        background: var(--grapefruit);
        font: 600 12px/24px var(--font-family);
        text-align: center;
        display: block;
        color: #fff;
        width: 100.42px;
        height: 24px;
      }
    }
  }

  &.content-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner-wrapper {
    @extend %wrapper;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    position: relative;

    .faq {
      text-decoration: none;
      font: 300 15px/22px var(--font-family);
      margin-bottom: 24px;
    }

    .tnc {
      font: 300 13px/13px var(--font-family);
      color: var(--text-light);
      text-decoration-color: var(--text-light);
    }
  }
}

.logo {
  margin: 0 auto 40px;
}

@include respond-to(desktop) {
  .main {
    border-radius: 0;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .content {
      margin-top: 64px;
    }
  }
}
