%center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

%bg-icon-info {
  background: var(--primary) url('../assets/icon-info.svg') 16px 8px no-repeat;
  background-size: 16px;

  &:hover {
    background: var(--primary-hover) url('../assets/icon-info.svg') 16px 8px
      no-repeat;
    background-size: 16px;
  }
}

%btn {
  display: block; // fallback display value
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  border-radius: 6px;
  padding: 8px 40px;
  width: 100%;
  height: 48px;
  font: 600 16px/16px var(--font-family);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: #fff;
  background: var(--primary);

  &:disabled {
    background-color: var(--lines);
    color: #6b748d;
    pointer-events: none;
  }

  &:enabled {
    &:hover {
      background: #3445c1;

      &.loading {
        background: var(--primary);
      }
    }
  }

  &.secondary {
    font-size: 13px;
    line-height: 16px;

    &.info {
      @extend %bg-icon-info;
    }
  }

  &.outline {
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: var(--white);

    &:disabled {
      border-color: var(--text-placeholder);
      color: var(--text-light);
      background-color: transparent;
      pointer-events: none;
    }

    &:enabled:hover {
      border-color: var(--primary-hover);
      color: var(--primary-hover);
      background-color: transparent;
    }
  }

  &.black-outline {
    border: 1px solid #000;
    color: #000;
    background-color: #fff;

    &:disabled {
      opacity: 0.5;
    }

    &:enabled:hover {
      border-color: #000;
      color: #000;
      background-color: #fff;
    }
  }

  &.loading {
    pointer-events: none;
  }

  img {
    display: inline-block;
    vertical-align: middle;
  }
}

%checkbox {
  display: flex;
  align-items: center;
  position: relative;

  > i {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
  }

  > input[type='checkbox'] {
    appearance: none;
    opacity: 0;
    width: 0;
    visibility: hidden;

    &:checked {
      + i {
        background: url('../assets/icon-checked.svg') center no-repeat;
        background-size: 24px 24px;
      }
    }

    &:not(:checked) {
      + i {
        border: 1px solid var(--text-placeholder);
        border-radius: 6px;
      }
    }
  }

  span {
    font: 300 15px/22px var(--font-family);
    color: var(--text-medium);
    margin-left: 24px;
  }
}

%message {
  display: block;
  padding: 8px 16px;
  font-size: 13px;
  line-height: 16px;
  color: var(--primary);
  border-radius: 4px;
  font-weight: 600;

  &.info {
    background: var(--primary-selected) url('../assets/icon-info-purple.svg')
      16px 8px no-repeat;
    background-size: 16px;
    padding-left: 40px;
  }
}

%wrapper {
  width: 100%;
  max-width: 432px;
  margin: 0 auto;
}

.error-wrapper {
  margin-top: 8px;
  background: var(--error-light) url('../assets/icon-exclamation-pink.svg') 16px
    8px no-repeat;
  background-size: 16px;
  padding: 8px 8px 8px 40px;
  border-radius: 4px;
  width: 100%;

  p {
    font: 600 13px/16px var(--font-family) !important;
    color: var(--error) !important;
  }
}

.info-wrapper {
  margin-top: 8px;
  background: var(--primary-selected) url('../assets/icon-exclamation-blue.svg')
    16px 8px no-repeat;
  background-size: 16px;
  padding: 8px 8px 8px 40px;
  border-radius: 4px;

  p {
    font: 600 13px/16px var(--font-family) !important;
    color: var(--info) !important;
  }

  &.gray {
    color: var(--text-dark);
    background: var(--block-background-app)
      url('../assets/icon-exclamation-gray.svg') 16px 8px no-repeat;
    background-size: 16px;

    p {
      color: var(--text-dark) !important;
    }
  }

  &.block {
    border-radius: 6px;
    padding: 16px 16px 16px 40px;
    background-position: 16px 16px;

    h3 {
      margin-bottom: 8px;
      font: 700 13px/16px var(--font-family);
    }

    p {
      margin: 0 0 8px 0;
      font: 400 13px/22px var(--font-family) !important;
    }

    li {
      list-style: disc;
      margin: 0 0 8px 16px;
      font: 400 13px/22px var(--font-family);
    }
  }
}
