@font-face {
  font-family: 'Filson Pro';
  src: url('../assets/fonts/FilsonPro-Book.woff2') format('woff2'),
    url('../assets/fonts/FilsonPro-Book.woff') format('woff');
  font-weight: 100 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0030-0039, U+00A5;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('../assets/fonts/FilsonPro-Medium.woff2') format('woff2'),
    url('../assets/fonts/FilsonPro-Medium.woff') format('woff');
  font-weight: 500 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0030-0039, U+00A5;
}
