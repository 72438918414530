@import '../../styles/base';

.disabled {
  display: none;
}

.payment {
  margin: 16px auto 32px;
  position: relative;

  .installments {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    .steps {
      display: flex;
      position: absolute;
      left: calc((100% / 6) - 12px);
      right: calc((100% / 6) - 12px);
      align-items: center;
      justify-content: space-between;
      margin-bottom: -37px;
      top: 13px;
      z-index: 1;

      i {
        flex: 0 0 24px;
        display: block;
        height: 24px;
        border-radius: 50%;
        border: 2px solid var(--highlight);
        text-align: center;
        color: var(--highlight);
        font: 600 12px/20px var(--font-family);
        margin: 0 7px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      i {
        background: white;
      }

      img {
        object-fit: contain;
        max-width: 30%;
      }
    }

    .total {
      font-weight: 600;
    }

    .three-installments {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .installment {
      white-space: break-spaces;
      padding-top: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      height: 112px;
      text-align: center;
      position: relative;
      background: var(--background-gray);
      font-size: 15px;
      min-width: 107px;

      &.today {
        background: rgba(114, 128, 255, 0.1);
        border-radius: 6px 0 0 0;

        time,
        .amount {
          color: var(--highlight);
        }

        time {
          font-family: var(--font-family);
        }
      }

      &:nth-child(2) {
        margin: 0 2px;
      }

      &:nth-child(3) {
        border-radius: 0 6px 0 0;

        &::before {
          height: 50%;
        }
      }

      &.total {
        flex: 1 1 100%;
        padding: 12px;
        line-height: 1;
        height: auto;
        margin-top: 2px;
        border-radius: 0 0 6px 6px;
        flex-direction: row;
      }

      .highlight {
        color: var(--highlight);
        margin-right: 8px;
      }

      time {
        font: 300 13px/1 var(--font-family);
        width: 60px;
        margin: 0 12px;
        color: var(--text-light);
        display: block;
      }

      .amount {
        margin-bottom: 12px;
        display: block;
        color: var(--text-dark);
        font: 600 15px/15px var(--font-family);
      }
    }
  }
}

.logo {
  margin: 8px auto 40px;
}

.form-title {
  margin-bottom: 12px;
  font: 600 15px/1 var(--font-family);
  letter-spacing: 0.02em;
  color: var(--text-dark);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.card {
    text-align: left;

    button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.02em;
      color: var(--primary);
    }
  }

  .monthly {
    color: var(--highlight);
  }

  img {
    display: block;
    margin-left: 8px;
  }
}

.card-form-title {
  margin-bottom: 12px;
  font: 600 15px/1 var(--font-family);
  letter-spacing: 0.02em;
  color: var(--text-dark);
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  .label {
    display: flex;
    align-items: center;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: var(--primary);
  }

  .monthly {
    color: var(--highlight);
  }

  img {
    display: block;
    margin-left: 8px;
  }
}

.confirm-hint {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  margin: 24px auto 4px;
  letter-spacing: 0.02em;
}

.add-card-form-show {
  display: flex;
}

.add-card-form-hide {
  display: none;
}

.btn-pay {
  @extend %btn;
  background: var(--primary) url('../../assets/icon-lock-white.svg') 16px center
    no-repeat;
  background-size: 16px;
  padding: 0 40px;
  margin-top: 8px;

  &:enabled {
    &:hover {
      background: var(--primary-hover) url('../../assets/icon-lock-white.svg')
        16px center no-repeat;
      background-size: 16px;
    }
  }
}

.disabled {
  color: var(--text-light);
}

.enabled {
  color: var(--primary);
}

@media only screen and (max-width: 374px) {
  .payment {
    .installments {
      .installment {
        min-width: 90px;
      }

      .steps {
        img {
          max-width: 25%;
        }
      }
    }
  }
}
