@import '../../styles/base';

.wrapper {
  flex: 1 1 calc(100vh - var(--header-height));
  height: calc(100vh - var(--header-height));
  background: var(--background-gray);
  overflow: auto;
}

%_h2 {
  padding-top: 24px;
  margin-bottom: 16px;
  color: var(--text-dark);
  font: 600 24px/32px var(--font-family);
}

.article {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 24px 32px 46px;
  color: var(--text-dark);
  font: 300 15px/28px var(--font-family);

  .title {
    font: 600 28px/36px var(--font-family);
  }

  a {
    color: var(--text-light);
  }

  > time {
    font: 600 13px/16px var(--font-family);
    color: var(--text-light);
  }

  b,
  h1,
  h2 {
    font-weight: 600;
    color: var(--text-dark);
  }

  h1 {
    font-size: 28px;
    line-height: 36px;
    padding: 16px 0;
  }

  h2 {
    @extend %_h2;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: none;
  }

  ol,
  ul {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    li {
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  > ol,
  > ul {
    counter-reset: level-one;

    > li {
      position: relative;

      &::marker {
        @extend %_h2;
      }

      > h2 {
        &::before {
          counter-increment: level-one;
          content: counter(level-one) '. ';
        }
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      ol {
        li {
          padding-left: 24px;
          font: 300 15px/28px var(--font-family);

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        p {
          &:first-child {
            &::before {
              margin-right: 4px;
              font-style: italic;
              font-weight: 600;
            }
          }
        }
      }

      > ol {
        counter-reset: level-two;
        list-style-type: none;

        > li {
          counter-increment: level-two;

          > p {
            &:first-child {
              &::before {
                content: counter(level-one) '.' counter(level-two) '.';
              }
            }
          }

          > ol {
            > li {
              counter-increment: level-three;

              > p {
                &:first-child {
                  &::before {
                    content: counter(level-one) '.' counter(level-two) '.'
                      counter(level-three) '. ';
                  }
                }
              }

              > ol {
                > li {
                  counter-increment: level-four;

                  > p {
                    &:first-child {
                      &::before {
                        content: counter(level-one) '.' counter(level-two) '.'
                          counter(level-three) '.' counter(level-four) '. ';
                      }
                    }
                  }

                  > ol {
                    > li {
                      counter-increment: level-five;

                      > p {
                        &:first-child {
                          &::before {
                            content: counter(level-one) '.' counter(level-two)
                              '.' counter(level-three) '.' counter(level-four)
                              '.' counter(level-five) '. ';
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
