@import '../../styles/base';

.input-box {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;

  .tip,
  .eye {
    margin: 0 16px 0 8px;
  }

  label {
    white-space: nowrap;
    display: block;
    font-size: 13px;
    line-height: 13px;
    color: var(--text-light);
    margin-bottom: 8px;

    &.focused {
      color: var(--highlight);
    }

    &.error {
      color: var(--error);
    }

    &.read-only {
      color: var(--text-light);
    }

    span {
      color: var(--text-placeholder);
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--lines);
    border-radius: 6px;
    padding: 1px;
    height: 48px;
    transition: border 0.1s ease-in-out;

    &.focused {
      border-color: var(--highlight);
    }

    &.error {
      border-color: var(--error);
    }

    &.error,
    &.focused {
      border-width: 2px;
    }

    &.disabled {
      border-width: 0;
      padding: 0;
    }

    &.read-only {
      padding: 0;
      border: none;
    }
  }

  input {
    display: block;
    width: 100%;
    height: 44px;
    font: 300 14px/48px var(--font-family);
    padding: 0 16px;
    color: #333;
    letter-spacing: 0.02em;
    caret-color: var(--highlight);
    border: 0;
    background: transparent;

    &::placeholder {
      color: var(--text-placeholder);
    }

    &:read-only {
      user-select: none;
      background-color: var(--background-gray);
      height: 48px;
      border-radius: 6px;
    }

    &.zip {
      padding-left: 40px;
      padding-right: 0;
      background: url('../../assets/icon-zip.svg') 16px center no-repeat;
      background-size: 16px;
    }

    &.phone {
      padding: 0 13px 0 51px;
      background: url('../../assets/icon-flag-japan.svg') 13px center no-repeat;
      background-size: 30px 20px;
    }

    &.pin {
      padding-left: 40px;
      background: url('../../assets/icon-lock-light-gray.svg') 16px center
        no-repeat;
      background-size: 16px;
    }

    &.password {
      padding-right: 8px;

      &.password-management {
        padding-right: 38px;
      }
    }

    &.dateOfBirth {
      display: flex;

      &::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    &:focus-visible {
      outline: 0;
    }
  }
}
