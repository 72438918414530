@import '../../styles/base';

.wrapper {
  border: 1px solid var(--lines);
  border-radius: 6px;
  min-height: 58px;
  padding: 16px;
  background: white;

  h1 {
    font: 600 15px/26px var(--font-family);
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-right: 16px;
    }

    .active {
      color: var(--primary);
    }
  }

  .content {
    margin-top: 16px;

    p {
      font: 300 15px/28px var(--font-family);

      &:nth-child(n + 1) {
        margin-top: 8px;
      }
    }
  }
}
