@import '../../styles/base';

.desc-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .desc {
    font: 300 15px/22px var(--font-family);
  }

  .space {
    margin-left: 10px;
  }
}
