@import '../../../styles/base';

.main {
  padding: 24px 0 0;

  .content-header {
    margin-bottom: 8px;

    h2 {
      margin: 0 24px 4px;
      padding: 0;
      text-align: center;
      font: 600 16px/24px var(--font-family);
      color: var(--text-dark);
    }

    h3 {
      margin: 0 24px 8px;
      padding: 0 0 4px;
      border-bottom: 1px solid var(--lines);
      text-align: center;
      font: 300 12px/18px var(--font-family);
      color: var(--text-dark);
    }
  }

  .issuer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .challenge-window-size-01 {
      width: 250px;
      height: 400px;
    }
    .challenge-window-size-02 {
      width: 390px;
      height: 400px;
    }
  }
}

.disabled {
  color: var(--text-light);
}

@include respond-to(desktop) {
  .main {
    .content-header {
      h2 {
        margin: 0 0 4px;
      }
      h3 {
        margin: 0 0 8px;
      }
    }
  }
}
