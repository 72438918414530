@import '../../styles/base';

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 1000;
  padding: 24px;
  width: 100%;
  height: 100%;
  max-width: 446px;
  overflow: auto;
  background-color: var(--background-gray);
  animation: slide-in 0.3s;
  overscroll-behavior: contain;

  h1 {
    font: 600 20px/32px var(--font-family);
  }

  h2 {
    font: 700 16px/16px var(--font-family);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@include respond-to(mobile) {
  .modal {
    max-width: none;
    animation: none;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.header-back {
  visibility: hidden;
  align-self: flex-start;
  pointer-events: none;

  &.force-active {
    visibility: visible;
    pointer-events: all;
  }
}

.header-close {
  align-self: flex-end;
  pointer-events: all;
}

@include respond-to(mobile) {
  .header-back {
    visibility: visible;
    pointer-events: all;
  }
  .header-close {
    visibility: hidden;
    pointer-events: none;
  }
}

.kinds {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.kind {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.list {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(68, 86, 221, 0.12);
}

.items {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 8px;
  cursor: default;

  &.compact {
    gap: 16px;
  }

  :global(.info-wrapper) {
    margin: 0;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 8px 0 16px;
  height: 48px;
  font: 500 17px/17px var(--font-family);
  color: var(--text-dark);
  text-decoration: none;

  &.app {
    padding-left: 8px;
  }

  &:hover {
    background-color: var(--background-gray);
  }
}

.extra-items {
  border-top: 1px solid var(--lines);
}

.extra-items:only-child {
  border-top: 0;
}

.lhs,
.rhs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.rhs {
  gap: 8px;
}

.add-icon {
  margin: auto 4px;
}

.note {
  font: 400 12px/18px var(--font-family);
  color: var(--text-light);
}

.info-block {
  padding-bottom: 8px !important;
}

.test-label {
  padding: 0 8px;
  border-radius: 4px;
  font: 600 8px/20px var(--font-family);
  text-transform: uppercase;
  margin-left: 8px;

  &:global([class*='success']) {
    color: var(--success);
    border: 1px solid var(--success-light);
  }

  &:global([class*='soft-decline']) {
    color: var(--error);
    border: 1px solid var(--error-light);
  }

  &:global([class*='hard-decline']) {
    color: var(--error);
    background: var(--error-light);
  }
}
