@import '../../styles/base';

.form {
  // > * {
  //   &:not(:last-child) {
  //     margin-bottom: 16px;
  //   }
  // }

  .phone-input {
    margin-bottom: 16px;
  }

  p {
    color: #000;
    font-size: 16px;
    line-height: 23px;
  }

  // .pin-box {
  //   margin-bottom: 32px;
  //   p {
  //     width: 100%;
  //     font: 16px/20px var(--font-family);
  //     text-align: center;
  //     margin: 16px 0;
  //   }

  //   .pin {
  //     width: 161px;
  //     height: 72px;
  //     text-align: center;
  //     font: 56px/88.5px var(--font-family);
  //     border: 0;
  //     border-bottom: 1px solid #e5e7ec;
  //     color: #000;
  //     display: block;
  //     margin: 0 auto;

  //     // &::placeholder {
  //     //   color: #e5e7ec;
  //     // }
  //   }
  // }
}
