@import '../../styles/base';

.wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  background: var(--background-gray);
  box-shadow: none;

  .content {
    justify-content: center;
  }

  .card {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
    border-radius: 8px;
    padding: 32px;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(68, 86, 221, 0.18);
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 0;
    width: 164px;
    height: 164px;
  }

  .qr-code-image {
    height: 164px;
    width: 164px;
    margin: 16px 0 0;
  }

  h1 {
    padding-bottom: 64px;
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
  }

  .error {
    margin: 0 auto;
    max-width: 326px;
    width: 100%;
    height: 96px;
  }
}

.error-wrapper {
  margin-bottom: 16px;
}

.merchant-header {
  display: none;
}

@include respond-to(desktop) {
  .wrapper {
    background-color: #fff;
    box-shadow: 0px 2px 24px rgba(51, 23, 88, 0.12);
  }
  .merchant-header {
    display: block;
  }
}
