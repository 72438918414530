@import '../../styles/base';

.form {
  .phone-input {
    margin-bottom: 16px;
  }

  .input-box {
    margin: 0 0 40px;
  }

  .otp-secret-box {
    margin: 0 0 40px;

    .otp-secret {
      margin-bottom: 16px;
    }

    p {
      width: 100%;
      font: 15px/22px var(--font-family);

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      em {
        font-family: var(--font-family);
      }

      a {
        text-decoration: none;
      }
    }
  }

  .tnc {
    color: var(--text-light);
    text-align: center;
    margin-bottom: 8px;
    font: 13px/22px var(--font-family);

    a {
      color: var(--text-light);
    }
  }
}

.phone-not-functional {
  margin-bottom: 24px;
  font: 600 15px/15px var(--font-family);
  color: var(--primary);
  cursor: pointer;
  outline: none;

  img {
    display: inline;
    vertical-align: top;
    margin: -1px 6px 0 0;
  }

  &:hover {
    color: var(--primary-hover);
  }
}

.phone-not-functional-modal {
  padding: 4px;
  max-width: 300px;

  .title {
    margin-bottom: 8px;
    font: 700 18px/30px var(--font-family);
  }

  .message {
    margin-bottom: 24px;
    font: 15px/26px var(--font-family);
    color: var(--text-light);
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .action {
    padding: 8px;
  }
}
