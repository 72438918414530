@import '../../styles/base';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;

  .fields {
    flex: 1;
  }

  .with-hint {
    display: flex;
    align-items: center;
  }

  fieldset {
    .holder {
      margin-bottom: 8px;
    }
  }

  iframe {
    height: 48px;
    width: 100%;
  }

  .card-name {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    border: 1px solid var(--lines);
    border-radius: 6px;
  }

  .card-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    border: 1px solid var(--lines);
    border-radius: 6px;
    height: 48px;
    margin-top: 6px;

    .expiry {
      width: 72px;
    }

    .cvc {
      width: 57px;
      margin-left: 8px;
      margin-bottom: 2px;
    }
  }

  .loading-wrapper {
    .card-content-wrapper {
      padding-left: 13px;
    }

    .loading {
      height: 18px;
      background: var(--ghost);
    }

    .card-name .loading {
      margin-left: 13px;
      width: 130px;
    }

    .number {
      flex: 1;

      .loading {
        margin-left: 8px;
        width: 100px;
        border-radius: 2px;
      }
    }

    .expiry {
      display: flex;
      width: 72px;
      color: var(--text-light);

      .loading:nth-child(1) {
        width: 26px;
        margin-right: 4px;
      }

      .loading:nth-child(2) {
        width: 21px;
        margin-left: 4px;
      }
    }
  }
}

.add-card-form-show {
  display: flex;
  flex-direction: column;
}

.add-card-form-hide {
  display: none;
}

.btn-add-card-disabled {
  @extend %btn;
  background: var(--text-light) url('../../assets/icon-lock-dark-gray.svg') 16px
    center no-repeat;
  background-size: 16px;
  padding: 0 16px;
  margin: 17px 0 0;
}

.btn-add-card-enabled {
  @extend %btn;
  background: var(--primary) url('../../assets/icon-lock-white.svg') 16px center
    no-repeat;
  background-size: 16px;
  padding: 0 16px;
  margin: 17px 0 0;

  &:enabled {
    &:hover {
      background: var(--primary-hover) url('../../assets/icon-lock-white.svg')
        16px center no-repeat;
      background-size: 16px;
    }
  }
}

.secure {
  display: block;
  text-align: center;
  color: var(--info);
  margin-top: 16px;
  font: 600 11px/11px var(--font-family);
}

.disabled {
  color: var(--text-light);
}

.enabled {
  color: var(--primary);
}

.check-label {
  @extend %checkbox;
  margin: 16px 0 24px;
}
