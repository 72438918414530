@import '../styles/base';

.button {
  margin-top: 8px;
  border-radius: 4px;
  padding: 8px 17px 8px 40px;
  height: auto;
  text-align: left;
}

.message {
  margin-top: 8px;
}

.otp-method-modal {
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }

  .message {
    font-size: 17px;
    line-height: 28px;
    margin: 16px 0 24px 0;
    text-align: center;
  }

  .choices {
    width: 300px;
  }

  .resend-voice-btn {
    margin-top: 8px;
  }
}
