@import '../../styles/base';

.content {
  flex: 1;
  display: flex;
  flex-direction: column;

  .title {
    font: 600 20px/32px var(--font-family);
    color: var(--text-dark);
    margin-bottom: 16px;
  }

  .desc {
    font: 300 15px/22px var(--font-family);
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 8px;
    }
  }

  .faq {
    font-weight: 500;
    text-decoration: none;
  }

  button {
    margin-top: 40px;
  }
}
