@import '../../styles/base';

.header {
  flex: 0 0 var(--header-height);
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 0 24px;
  min-width: var(--min-device-width);

  img {
    display: block;
    cursor: pointer;
  }

  h1 {
    font: 600 16px/16px var(--font-family);
    text-transform: uppercase;
  }
}

.node {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;

  .text {
    margin-left: 8px;
    font: 300 16px/24px var(--font-family);
    color: var(--primary);
  }
}

.placeholder {
  width: 112px;
  height: 24px;
}

@include respond-to(mobile) {
  .placeholder {
    width: 24px;
  }
}
